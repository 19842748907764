<template>
  <div class="text-center">
    <v-dialog v-model="mostrar" width="1000"  @keydown.esc="$emit('ocultar')" @click:outside="$emit('ocultar')">
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Desglose del total almacén
          </span>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="encabezados"
            :items="datos"
            hide-default-footer
            disable-pagination
            class="mb-5"
          >
            <template v-slot:item.productos.precios[0].precio="{ item }">
              {{ formatoMoneda(item.productos.precios[0].precio) }}
            </template>
            <template v-slot:item.total="{ item }">
              {{ formatoMoneda(item.productos.precios[0].precio * item.cantidad) }}
            </template>
            <template v-slot:no-data>No existen detalles de almacén</template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('ocultar', true)">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DetalleTotalAlmacen',

  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      encabezados: [
        { text: 'Fecha', value: 'fecha' },
        { text: 'Producto', value: 'productos.nombre' },
        { text: 'Cantidad', value: 'cantidad' },
        { text: 'Precio', value: 'productos.precios[0].precio' },
        { text: 'Total', value: 'total' },
      ],
    };
  },
};
</script>