<template>
  <div class="text-center">
    <v-dialog v-model="mostrar" width="1000" @keydown.esc="$emit('ocultar')" @click:outside="$emit('ocultar')">
      <v-card>
        <v-card-title>
          <span class="text-h3">
            Desglose de efectividad de ruta
          </span>
        </v-card-title>

        <v-card-text>
          <v-simple-table class="elevation-2 mb-3">
            <thead>
              <tr>
                <th>Vendedor</th>
                <th>Fecha</th>
                <th>% Avance de ruta</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ vendedor }}</td>
                <td>{{ fecha }}</td>
                <td>{{ formatoNumero(procentajeRuta) }}%</td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-col cols="12">
            <v-btn color="primary" @click="downloadPdf" :loading="loadingPdf">Descargar PDF</v-btn>
            <v-btn color="green" @click="downloadExcel" :loading="loadingXlsx">Descargar Excel</v-btn>
          </v-col>

          <v-data-table :headers="encabezados" :items="sucursalesEnRuta" hide-default-footer disable-pagination
            class="mb-5">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Sucursales en ruta</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item.visitada="{ item }">
              <v-icon large color="green darken-2" v-if="null === item.motivos_no_visita_id">mdi-check-bold</v-icon>
              <v-icon large color="red darken-2" v-else>mdi-close-thick</v-icon>
            </template>
            <template v-slot:item.ventas="{ item }">
              <span v-if="item.ventas.length > 0 && undefined !== item.ventas">
                {{ formatoMoneda(sumarVentaPorRuta(item.ventas)) }}
              </span>
              <span v-else>{{ formatoMoneda(0) }}</span>
            </template>
            <template v-slot:item.venta_contado="{ item }">
              <span v-if="item.ventas.length > 0 && undefined !== item.ventas">
                {{ formatoMoneda(sumarContadoPorRuta(item.ventas)) }}
              </span>
              <span v-else>{{ formatoMoneda(0) }}</span>
            </template>
            <template v-slot:item.venta_credito="{ item }">
              <span v-if="item.ventas.length > 0 && undefined !== item.ventas">
                {{ formatoMoneda(sumarCreditoPorRuta(item.ventas)) }}
              </span>
              <span v-else>{{ formatoMoneda(0) }}</span>
            </template>
            <template v-slot:item.sucursal.devoluciones="{ item }">
              {{ formatoMoneda(sumarDevolucionesDetallesImporte(item.sucursal.devoluciones)) }}
            </template>
            <template v-slot:item.porcentaje_devolucion="{ item }">
              {{ formatoNumero(calcularPorcentajeDevolucion(item.ventas, item.sucursal.devoluciones)) }}%
            </template>
            <template v-slot:body.append>
              <tr>
                <td colspan="5"></td>
                <td class="font-weight-bold text-center">{{ formatoMoneda(totalImporteVentaEnRuta) }}</td>
                <td class="font-weight-bold text-center">{{ formatoMoneda(totalContadoEnRuta) }}</td>
                <td class="font-weight-bold text-center">{{ formatoMoneda(totalCreditoEnRuta) }}</td>
                <td class="font-weight-bold text-center">{{ formatoMoneda(totalDevolucionesDetallesImporteEnRuta) }}</td>
                <td class="font-weight-bold text-center">
                  {{ totalImporteVentaEnRuta > 0 ? formatoNumero((totalDevolucionesDetallesImporteEnRuta * 100) /
                    totalImporteVentaEnRuta) : 0 }}%
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-data-table :headers="encabezados" :items="sucursalesFueraRuta" hide-default-footer disable-pagination>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Sucursales fuera de ruta</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item.visitada="{ item }">
              <v-icon large color="green darken-2">mdi-check-bold</v-icon>
            </template>
            <template v-slot:item.ventas="{ item }">
              <span v-if="item.ventas.length > 0 && undefined !== item.ventas">
                {{ formatoMoneda(sumarVentaPorRuta(item.ventas)) }}
              </span>
              <span v-else>{{ formatoMoneda(0) }}</span>
            </template>
            <template v-slot:item.venta_contado="{ item }">
              <span v-if="item.ventas.length > 0 && undefined !== item.ventas">
                {{ formatoMoneda(sumarContadoPorRuta(item.ventas)) }}
              </span>
              <span v-else>{{ formatoMoneda(0) }}</span>
            </template>
            <template v-slot:item.venta_credito="{ item }">
              <span v-if="item.ventas.length > 0 && undefined !== item.ventas">
                {{ formatoMoneda(sumarCreditoPorRuta(item.ventas)) }}
              </span>
              <span v-else>{{ formatoMoneda(0) }}</span>
            </template>
            <template v-slot:item.sucursal.devoluciones="{ item }">
              {{ formatoMoneda(sumarDevolucionesDetallesImporte(item.sucursal.devoluciones)) }}
            </template>
            <template v-slot:item.porcentaje_devolucion="{ item }">
              {{ formatoNumero(calcularPorcentajeDevolucion(item.ventas, item.sucursal.devoluciones)) }}%
            </template>
            <template v-slot:body.append>
              <tr>
                <td colspan="5"></td>
                <td class="font-weight-bold text-center">{{ formatoMoneda(totalImporteVentaFueraRuta) }}</td>
                <td class="font-weight-bold text-center">{{ formatoMoneda(totalContadoFueraRuta) }}</td>
                <td class="font-weight-bold text-center">{{ formatoMoneda(totalCreditoFueraRuta) }}</td>
                <td class="font-weight-bold text-center">{{ formatoMoneda(totalDevolucionesDetallesImporteFueraRuta) }}
                </td>
                <td class="font-weight-bold text-center">
                  {{ totalImporteVentaFueraRuta > 0 ? formatoNumero((totalDevolucionesDetallesImporteFueraRuta * 100) /
                    totalImporteVentaFueraRuta) : 0 }}%
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('ocultar', true)">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <base-material-snackbar :value="snackbar" type="error">
      <template>
        {{ snackbarText }}
      </template>
    </base-material-snackbar>
  </div>
</template>

<script>
import BaseMaterialSnackbar from '@components/base/MaterialSnackbar.vue';

export default {
  name: 'DetalleEfectividadRuta',

  components: {
    BaseMaterialSnackbar,
  },

  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      encabezados: [
        { text: 'No. Cliente', value: 'index' },
        { text: 'Cliente', value: 'sucursal.cliente.razon_social' },
        { text: 'Sucursal', value: 'sucursal.nombre' },
        { text: 'Visitada', value: 'visitada' },
        { text: 'Ruta', value: 'ruta.nombre' },
        { text: 'Importe de venta', value: 'ventas', align: 'right' },
        { text: 'Contado', value: 'venta_contado', align: 'right' },
        { text: 'Crédito', value: 'venta_credito', align: 'right' },
        { text: 'Devolución', value: 'sucursal.devoluciones', align: 'right' },
        { text: '% de devolución sobre la venta', value: 'porcentaje_devolucion' },
        { text: 'Hora de visita', value: 'inicio_venta' },
      ],
      sucursalesEnRuta: [],
      sucursalesFueraRuta: [],
      fecha: '',
      vendedor: '',
      procentajeRuta: 0,
      snackbar: false,
      snackbarText: '',
      loadingPdf: false,
      loadingXlsx: false,
    };
  },

  watch: {
    datos(newValue, oldValue) {
      this.obtenerSucursales();
    },
  },

  computed: {
    totalImporteVentaEnRuta: function () {
      if (this.sucursalesEnRuta.length) {
        return this.sucursalesEnRuta.reduce((total, item) => {
          if (undefined !== item.ventas && item.ventas.length > 0) {
            return total + this.sumarVentaPorRuta(item.ventas);
          } else {
            return total + 0;
          }
        }, 0);
      }
      return 0;
    },
    totalContadoEnRuta: function () {
      if (0 === this.sucursalesEnRuta.length) {
        return 0;
      }

      return this.sucursalesEnRuta.reduce((total, item) => {
        if (undefined !== item.ventas && item.ventas.length > 0) {
          return total + this.sumarContadoPorRuta(item.ventas);
        } else {
          return total + 0;
        }
      }, 0);
    },
    totalCreditoEnRuta: function () {
      if (this.sucursalesEnRuta.length) {
        return this.sucursalesEnRuta.reduce((total, item) => {
          if (undefined !== item.ventas && item.ventas.length > 0) {
            return total + this.sumarCreditoPorRuta(item.ventas);
          } else {
            return total + 0;
          }
        }, 0);
      }
      return 0;
    },
    totalDevolucionesDetallesImporteEnRuta: function () {
      if (this.sucursalesEnRuta.length) {
        let total = 0;

        this.sucursalesEnRuta.forEach(item => {
          total += this.sumarDevolucionesDetallesImporte(item.sucursal.devoluciones)
        });

        return total;
      }
      return 0;
    },

    totalImporteVentaFueraRuta: function () {
      if (this.sucursalesFueraRuta.length) {
        return this.sucursalesFueraRuta.reduce((total, item) => {
          if (undefined !== item.ventas && item.ventas.length > 0) {
            return total + this.sumarVentaPorRuta(item.ventas);
          } else {
            return total + 0;
          }
        }, 0);
      }
      return 0;
    },
    totalContadoFueraRuta: function () {
      if (this.sucursalesFueraRuta.length) {
        return this.sucursalesFueraRuta.reduce((total, item) => {
          if (undefined !== item.ventas && item.ventas.length > 0) {
            return total + this.sumarContadoPorRuta(item.ventas);
          } else {
            return total + 0;
          }
        }, 0);
      }
      return 0;
    },
    totalCreditoFueraRuta: function () {
      if (this.sucursalesFueraRuta.length) {
        return this.sucursalesFueraRuta.reduce((total, item) => {
          if (undefined !== item.ventas && item.ventas.length > 0) {
            return total + this.sumarCreditoPorRuta(item.ventas);
          } else {
            return total + 0;
          }
        }, 0);
      }
      return 0;
    },
    totalDevolucionesDetallesImporteFueraRuta: function () {
      if (this.sucursalesFueraRuta.length) {
        let total = 0;

        this.sucursalesFueraRuta.forEach(item => {
          total += this.sumarDevolucionesDetallesImporte(item.sucursal.devoluciones)
        });

        return total;
      }
      return 0;
    },
  },

  methods: {
    obtenerSucursales() {
      this.snackbarText = '';

      this.$http.get(`/sucursales/ruta/${this.datos.ruta_id}`, {
        params: {
          fecha: this.datos.fecha,
          vendedor_id: this.datos.vendedor_id,
        }
      }).then((response) => {
        this.fecha = response.data.data.fecha;
        this.vendedor = response.data.data.vendedor.razon_social;
        this.sucursalesEnRuta = response.data.data.en_ruta;
        this.sucursalesFueraRuta = response.data.data.fuera_ruta;
        this.calcularPorcentajeRuta(response.data.data.en_ruta);
      }).catch(() => {
        this.snackbarText = 'Error al consultar el desglose de efectividad de ruta.';
        this.snackbar = true;
      });
    },

    sumarDevolucionesDetallesImporte(devoluciones) {
      if (devoluciones.length < 1 || undefined === devoluciones) {
        return 0;
      }

      let totalDetalles = 0;
      devoluciones.forEach(devolucion => {
        devolucion.detalles.forEach(detalle => {
          totalDetalles += detalle.importe;
          if (detalle.impuestos) {
            detalle.impuestos.forEach(impuestos => {
              if (impuestos.pivot)
                totalDetalles += parseFloat(impuestos.pivot.importe);
            });
          }

        });
      });

      return totalDetalles;
    },

    calcularPorcentajeDevolucion(venta, devolucion) {
      if (venta.length < 1 || devolucion.length < 1) {
        return 0;
      }

      return (this.sumarDevolucionesDetallesImporte(devolucion) * 100) / this.sumarVentaPorRuta(venta);
    },

    calcularPorcentajeRuta(items) {
      const asignados = items.length;
      const noVisitados = items.filter(item => null === item.motivos_no_visita_id);

      this.procentajeRuta = (noVisitados.length * 100) / asignados;
    },

    sumarVentaPorRuta(ventas) {
      let total = 0;

      ventas.forEach(element => {
        total += element.total;
      });

      return total;
    },

    sumarContadoPorRuta(ventas) {
      let total = 0;

      ventas.forEach(venta => {
        if (1 === venta.metodo_pago_id) {
          total += venta.total;
        }
      });

      return total;
    },

    sumarCreditoPorRuta(ventas) {
      let total = 0;

      ventas.forEach(venta => {
        if (1 !== venta.metodo_pago_id) {
          total += venta.total;
        }
      });

      return total;
    },

    downloadPdf() {
      this.loadingPdf = true;
      this.$http({
        url: `/reportes/rutas/general/detalle/pdf/${this.datos.ruta_id}`,
        method: "GET",
        responseType: "blob",
        params: {
          fecha: this.datos.fecha,
          vendedor_id: this.datos.vendedor.id
        },
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteRutasGeneral.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        this.loadingPdf = false;
      });
    },

    downloadExcel(item) {
      this.loadingXlsx = true;
      this.$http({
        url: `/reportes/rutas/general/detalle/excel/${this.datos.ruta_id}`,
        method: "GET",
        responseType: "blob",
        params: {
          fecha: this.datos.fecha,
          vendedor_id: this.datos.vendedor.id
        },
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteDesgloseRutas.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        this.loadingXlsx = false;
      });
    },
  },
};
</script>