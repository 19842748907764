<template>
  <v-container fluid>
    <base-material-card
      icon="fa fa-truck"
      title="Reporte de Rutas"
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="4">
          <v-menu v-model="menu_fecha_desde" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="form.fecha_desde" label="Fecha inicial" readonly v-on="on"
                            :error-messages="errors.fecha_desde"></v-text-field>
            </template>
            <v-date-picker v-model="form.fecha_desde" @input="menu_fecha_desde = false; load()"
                           locale="es-MX"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu v-model="menu_fecha_hasta" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="form.fecha_hasta" label="Fecha final" readonly v-on="on"
                            :error-messages="errors.fecha_hasta"></v-text-field>
            </template>
            <v-date-picker v-model="form.fecha_hasta" @input="menu_fecha_hasta = false; load()"
                           locale="es-MX"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <custom-select
            url="/vendedores/all"
            text="razon_social"
            label="Vendedor"
            @change="
							(value) => {
								form.vendedor_id = value;
								load();
							}
						"
            :valor="form.vendedor_id"
            :first-select="true"
            first-text="Todos"
            multiple
          ></custom-select>
        </v-col>
        <v-col cols="4">
          <custom-select
            url="/rutas"
            text="nombre"
            label="Ruta"
            @change="(value) => {form.ruta_id = value; load()}"
            :paginate="false"
            :valor="form.ruta_id"
            :first-select="true"
            first-text="Todos"
            multiple
          ></custom-select>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="form.dias"
            chips
            deletable-chips
            multiple
            label="Dias de Visitas"
            item-text="nombre"
            item-value="id"
            :items="dias"
            :error-messages="errors.dias"
            @change="load()"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="4">
          <custom-select-2
            url="/vendedores/subcategorias"
            text="nombre"
            label="Subcategoria"
            :valor="form.vendedor_subcategoria_id"
            :first-select="true"
            first-text="Todos"
            paginate
            @change="(value) => {form.vendedor_subcategoria_id = value; load()}"
          ></custom-select-2>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="downloadPdf" :loading="loadingPdf">Descargar PDF</v-btn>
          <v-btn color="green" @click="downloadExcel" :loading="loadingXlsx">Descargar Excel</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="rutas"
            :loading="loading"
            hide-default-footer
            disable-pagination
            disable-form
          >
            <template v-slot:item.porcentaje_efectividad="{ item }">
              <a @click="abrirModalDetalleEfectividadRuta(item)">{{ item.porcentaje_efectividad }} %</a>
            </template>
            <template v-slot:item.porcentaje_sobre_venta="{ item }">
              {{ item.porcentaje_sobre_venta }}%
            </template>
            <template v-slot:item.ventas_impuesto_trasladado="{ item }">
              {{ formatoMoneda(item.ventas_impuesto_trasladado) }}
            </template>
            <template v-slot:item.venta_credito="{ item }">
              {{ formatoMoneda(item.venta_credito) }}
            </template>
            <template v-slot:item.venta_contado="{ item }">
              {{ formatoMoneda(item.venta_contado) }}
            </template>
            <template v-slot:item.ventas_total="{ item }">
              {{ formatoMoneda(item.ventas_total) }}
            </template>
            <template v-slot:item.total_almacen="{ item }">
              <a @click="abrirModalDetalleTotalAlmacen(item.detalle_total_almacen)">{{ formatoMoneda(item.total_almacen) }}</a>
            </template>
            <template v-slot:item.total_devolucion="{ item }">
              {{ formatoMoneda(item.total_devolucion) }}
            </template>
            <template v-slot:item.acciones="{ item }">
              <v-tooltip top v-if="item.lat && item.lng">
                <template v-slot:activator="{ on }">
                  <v-btn fab icon x-small v-on="on" @click="showMap(item)">
                    <v-icon>far fa-file-alt</v-icon>
                  </v-btn>
                </template>
                <span>Movimientos</span>
              </v-tooltip>
            </template>
            <template v-slot:body.append>
              <tr>
                <td colspan="3"></td>
                <td class="font-weight-bold text-center">
                  {{ clientes_asignados }}
                </td>
                <td class="font-weight-bold text-center">
                  {{ clientes_visitados_ruta }}
                </td>
                <td class="font-weight-bold text-center">
                  {{ total_clientes_no_visitados }}
                </td>
                <td class="font-weight-bold text-center">
                  {{ formatoNumero(calcularPorcentajeEfectividad()) }}%
                </td>
                <td class="font-weight-bold text-center">
                  {{ clientes_visitados_otra_ruta }}
                </td>
                <td class="font-weight-bold text-center">
                  {{ total_clientes_visitados }}
                </td>
                <td class="font-weight-bold">
                  {{ formatoMoneda(ventas_impuesto_trasladado) }}
                </td>
                <td class="font-weight-bold">
                  {{ formatoMoneda(ventas_total) }}
                </td>
                <td class="font-weight-bold">
                  {{ formatoMoneda(venta_credito) }}
                </td>
                <td class="font-weight-bold">
                  {{ formatoMoneda(venta_contado) }}
                </td>
                <td class="font-weight-bold">
                  {{ formatoMoneda(total_devolucion) }}
                </td>
                <td class="font-weight-bold">
                  {{ formatoNumero(calcularPorcentajeDevolucion()) }}%
                </td>
                <td class="font-weight-bold">
                  {{ formatoMoneda(total_almacen) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col col="12">
          <v-pagination 
            v-model="form.page" class="my-4"
            :length="form.last_page"
            @input="load" :total-visible="10"
            color="primary"
          ></v-pagination>
        </v-col>
      </v-row>
      <detalle-efectividad-ruta
        :mostrar="modalPorcentajeEfectividad"
        :datos="datosModalPorcentajeEfectividad"
        @ocultar="modalPorcentajeEfectividad = false"
      ></detalle-efectividad-ruta>

      <detalle-total-almacen
        :mostrar="modalDetalleTotalAlmacen"
        :datos="datosModalDatalleTotalAlmacen"
        @ocultar="modalDetalleTotalAlmacen = false"
      ></detalle-total-almacen>
    </base-material-card>

  </v-container>
</template>
<script>

import moment from 'moment';
import CustomSelect from "@views/dashboard/component/CustomSelect.vue";
import CustomSelect2 from "@views/dashboard/component/v2/CustomSelect.vue";
import DetalleEfectividadRuta from "@views/dashboard/component/v2/DetalleEfectividadRuta.vue";
import DetalleTotalAlmacen from '../../component/v2/DetalleTotalAlmacen.vue';


export default {
  name: 'ReporteRutasGeneral',
  components: {
    CustomSelect,
    CustomSelect2,
    DetalleEfectividadRuta,
    DetalleTotalAlmacen,
  },
  data: () => ({
    errors: {},
    headers: [
      {text: "Fecha", value: "fecha", sortable: false},
      {text: "Ruta", value: "ruta.nombre", sortable: false},
      {text: "Vendedor", value: "vendedor.razon_social", sortable: false},
      {text: "Clientes asignados", value: "clientes_asignados", sortable: false, align: 'center'},
      {text: "Clientes visitados de la ruta", value: "clientes_visitados_ruta", sortable: false, align: 'center'},
      {text: "Clientes no visitados", value: "total_clientes_no_visitados", sortable: false, align: 'center'},
      {text: "Porcentaje Efectividad", value: "porcentaje_efectividad", sortable: false},
      {text: "Clientes visitados de otra ruta", value: "clientes_visitados_otra_ruta", sortable: false, align: 'center'},
      {text: "Total clientes visitados", value: "total_clientes_visitados", sortable: false, align: 'center'},
      {text: "Total Impuestos", value: "ventas_impuesto_trasladado", sortable: false},
      {text: "Total Vendido", value: "ventas_total", sortable: false},
      {text: "Venta credito", value: "venta_credito", sortable: false},
      {text: "Venta contado", value: "venta_contado", sortable: false},
      {text: "Total Devolución", value: "total_devolucion", sortable: false},
      {text: "% de devolución sobre la venta", value: "porcentaje_sobre_venta", sortable: false},
      {text: "Total almacén", value: "total_almacen", sortable: false},
      {text: "Hora de primera visita", value: "hora_primera_visita", sortable: false},
      {text: "Hora de última visita", value: "hora_ultima_visita", sortable: false},
      {text: "Tiempo recorrido", value: "tiempo_recorrido", sortable: false},
    ],
    form: {
      fecha_desde: moment().format('YYYY-MM-DD'),
      fecha_hasta: moment().format('YYYY-MM-DD'),
      vendedor_id: "",
      ruta_id: "",
      dias: [],
      last_page: 1,
      page: 1,
      model: true,
      vendedor_subcategoria_id: '',
    },
    pagination: {
      fecha_inicial: null,
      fecha_final: null,
      page: 1,
      last_page: 1
    },
    dias: [],
    menu_fecha_desde: false,
    menu_fecha_hasta: false,
    loading: false,
    rutas: [],
    total: 0,
    totales: {
      impuestos: [],
    },
    isMapShow: false,
    marker: null,
    rutasRows:[],
    modalPorcentajeEfectividad: false,
    datosModalPorcentajeEfectividad: [],
    modalDetalleTotalAlmacen: false,
    datosModalDatalleTotalAlmacen: [],
    loadingPdf: false,
    loadingXlsx: false,
  }),
  mounted() {
    this.dias = [
      {
        id: 1, nombre: 'Domingo'
      },
      {
        id: 2, nombre: 'Lunes'
      },
      {
        id: 3, nombre: 'Martes'
      },
      {
        id: 4, nombre: 'Miercoles'
      },
      {
        id: 5, nombre: 'Jueves'
      },
      {
        id: 6, nombre: 'Viernes'
      },
      {
        id: 7, nombre: 'Sabado'
      }
    ];
    this.load();
  },
  computed: {
    ventas_impuesto_trasladado: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.ventas_impuesto_trasladado;
        }, 0);
      }
      return 0;
    },
    ventas_total: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.ventas_total;
        }, 0);
      }
      return 0;
    },
    venta_credito: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.venta_credito;
        }, 0);
      }
      return 0;
    },
    venta_contado: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.venta_contado;
        }, 0);
      }
      return 0;
    },
    total_devolucion: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.total_devolucion;
        }, 0);
      }
      return 0;
    },
    total_almacen: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.total_almacen;
        }, 0);
      }
      return 0;
    },
    clientes_asignados: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.clientes_asignados;
        }, 0);
      }
      return 0;
    },
    clientes_visitados_ruta: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.clientes_visitados_ruta;
        }, 0);
      }
      return 0;
    },
    total_clientes_no_visitados: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.total_clientes_no_visitados;
        }, 0);
      }
      return 0;
    },
    clientes_visitados_otra_ruta: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.clientes_visitados_otra_ruta;
        }, 0);
      }
      return 0;
    },
    total_clientes_visitados: function () {
      if (this.rutasRows.length) {
        return this.rutasRows.reduce((total, item) => {
          return total + item.total_clientes_visitados;
        }, 0);
      }
      return 0;
    },
  },
  methods: {
    showMap({lat, lng}) {
      this.marker = {lat: Number(lat), lng: Number(lng)};
      this.isMapShow = true;
    },
    calcularSubtotal(item) {
      return item.producto.precio_default.precio * item.cantidad;
    },
    calcularImpuestos(item) {
      const subtotal = this.calcularSubtotal(item);
      return item.producto.impuestos.reduce(
        (total, impuesto) => total + subtotal * (impuesto.porcentaje / 100),
        0
      );
    },
    reset() {
      this.form.page = 1;
      this.load();
    },
    load() {
      this.loading = true;
      this.rutas = [];
      this.$http
        .get("/reportes/rutas/general", {
          params: this.form,
        })
        .then((response) => {
          this.rutas = response.data.data;
          this.rutasRows = response.data.rows;
          this.total = response.data.cantidad_sum;
          this.totales = response.data.totales;
          this.form.last_page = response.data.last_page;
          this.form.page = response.data.current_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadPdf() {
      this.loadingPdf = true;
      this.$http({
        url: `/reportes/rutas/general/pdf`,
        method: "GET",
        responseType: "blob", // important
        params: this.form,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteRutasGeneral.pdf`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        this.loadingPdf = false;
      });
    },
    downloadExcel(item) {
      this.loadingXlsx = true;
      this.$http({
        url: `/reportes/rutas/general/excel`,
        method: "GET",
        responseType: "blob", // important
        params: this.form,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteRutasGeneral.xlsx`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        this.loadingXlsx = false;
      });
    },
    retryDownloadPdf(item) {
      this.$http
        .patch(`/facturas/${item.id}/retry-download`)
        .then((response) => {
          this.$store.commit("SET_SNACKBAR", {
            text: "Solicitud de generación de PDF exitosa; intente descarga más tarde",
            color: "success",
          });
        });
    },
    abrirModalDetalleEfectividadRuta(item) {
      this.datosModalPorcentajeEfectividad = item;
      this.modalPorcentajeEfectividad = true;
    },
    abrirModalDetalleTotalAlmacen(item) {
      this.datosModalDatalleTotalAlmacen = item;
      this.modalDetalleTotalAlmacen = true;
    },
    calcularPorcentajeEfectividad()
    {
      if (0 === this.clientes_visitados_ruta || 0 === this.clientes_asignados) {
        return 0;
      }
      
      return (this.clientes_visitados_ruta * 100) / this.clientes_asignados;
    },
    calcularPorcentajeDevolucion()
    {
      if (0 === this.total_devolucion || 0 === this.ventas_total) {
        return 0;
      }
      
      return (this.total_devolucion * 100) / this.ventas_total;
    }
  },
};
</script>
