<template>
  <v-autocomplete
    v-if="multiple"
    :value="valor"
    :items="items"
    :search-input.sync="search"
    :placeholder="placeholder"
    :item-text="text"
    item-value="id"
    :label="label"
    :error-messages="errorMessages"
    :dense="dense"
    :auto-select-first="firstSelect"
    :disabled="disabled"
    hide-no-data
    multiple
    chips
    small-chips
    deletable-chips
    @change="changeAutocomplete($event)"
  >
  </v-autocomplete>
  <v-autocomplete
    v-else
    :value="valor"
    :items="items"
    :search-input.sync="search"
    :placeholder="placeholder"
    :item-text="text"
    item-value="id"
    :label="label"
    :error-messages="errorMessages"
    :dense="dense"
    :disabled="disabled"
    :auto-select-first="firstSelect"
    hide-no-data
    @change="changeAutocomplete($event)"
  >
  </v-autocomplete>
</template>

<script>
import _ from 'lodash';

export default {
  name: "custom-select",
  props: {
    url: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: 'id'
    },
    text: {
      type: [String, Function],
      default: 'nombre'
    },
    errors: {
      type: String
    },
    paginate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    valor: {
      type: [Array, String, Object, Number],
      default: () => ([]),
    },
    params: {
      type: Object,
      default: () => {},
    },
    firstSelect: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false,
    },
    firstText: {
      type: String,
      default: 'Seleccione una opcion'
    },
    errorMessages: {
      type: [String, Array],
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      search: '',
      items: []
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    search(val, old) {
      if (val) {
        this.loadDataDebounce();
      } else {
        if (true === this.multiple) {
          this.$emit('change', []);
        } else {
          this.$emit('change', null);
        }
      }
    },

    // params: {
    //   handler: function (after, before) {
    //     if (after) {
    //       console.log('params true', this.label);
    //       console.log('--------->after', after);
    //       this.loadData();
    //     } else {
    //       console.log('params false');
    //       this.$emit('change', '')
    //       this.items = [];
    //     }
    //   },
    //   deep: true
    // },
  },
  methods: {
    changeAutocomplete(item){
      this.$emit('change', item);
    },

    loadDataDebounce: _.debounce(function () {
      this.loadData();
    }, 500),

    loadData() {
      let localParams = {
        search: this.search
      };

      this.$http.get(this.url, {
        params: {...localParams, ...this.params}
      }).then(response => {
        this.processResponse(response);
      });
    },

    processResponse(response) {
      // let first = {id: ''};
      let data = (this.paginate && response.data.data) ? response.data.data : response.data;
      // first[this.text] = this.firstText;

      // if (true === this.firstSelect) {
      //   data.unshift(first)
      // }

      if(true === this.paginate){
        if(data.length){
          this.items = data;
        }
      }else{
        this.items = data;
      }
    }
  }
}
</script>